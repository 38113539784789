@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.sideBar {
  height: 100%;
}


/* GoogleForm.css */
.googleFormContainer iframe {
  border: none; /* Removes the default iframe border */
  width: 100%; /* Ensures it takes the full container width */
  min-height: 650px;
}

@media (max-width: 600px) {
  .googleFormContainer iframe {
    height: 850px; /* Greater height for smaller screens */
  }
}

.googleFormContainer {
  padding: 20px;
  background-color: #1F2A40; /* Light grey background */
  border-radius: 8px; /* Rounded corners */
}